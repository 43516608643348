export default [
  { name: 'spring_open', x: 34, y: 0, width: 32, height: 39 },
  { name: 'spring_closed', x: 0, y: 0, width: 33, height: 19 },
  { name: 'grid', x: 0, y: 20, width: 20, height: 20 },
  { name: 'block_broken', x: 0, y: 41, width: 76, height: 23 },
  // { name: 'player', x: 0, y: 65, width: 66, height: 64 },
  // { name: 'block', x: 0, y: 130, width: 76, height: 22 },
  { name: 'player', x: 0, y: 65, width: 66, height: 80 },
  { name: 'block', x: 0, y: 146, width: 76, height: 22 },
];
